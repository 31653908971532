/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 5000, price: 5000 },
    { get: 10000, price: 10000 },
    { get: 20000, price: 19800, hot: 1 },
    { get: 50000, price: 49500, hot: 1 },
    { get: 100000, price: 99000, hot: 1 },
    { get: 500000, price: 495000, hot: 1 },
    { get: 1000000, price: 990000 },
    { get: 2000000, price: 1980000 },
    { get: 5000000, price: 4950000 },
  ],
  firstRechargeAmount: 10000,
  firstRechargedWithdrawNeedBets: 20000,
  defaultDepositGot: 100,
  defaultDepositPrice: 99,
  minDepositAmount: 10000,
  maxDepositAmount: 2000000,
  withdrawFeePercent: 0,
  minWithdrawAmount: 30000,
  maxWithdrawAmount: 1200000,
  maxWithdrawDailyAmount: 6000000,
  inviteWheel: {
    targetAmount: 300000,
  },
  luckyDraw: {
    marqueeAmoundList: [120, 480, 1200, 300, 30, 120, 48, 600, 480, 300, 3000, 600, 480, 60, 300, 30, 4800, 48, 60, 12],
  },
  addHomeScreenRewardFrom: 9,
  addHomeScreenRewardTo: 1800,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 0,
  useWithdrawLevel: 0,
};
