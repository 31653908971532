import enTranslation from '../lang/en.json';
import hiTranslation from '../lang/hindi.json';
import urTranslation from '../lang/ur.json';

export default {
    en: {
        name: "en",
        text: "English",
        langJson: enTranslation
    },
    hindi: {
        name: "hindi",
        text: "हिंदी",
        langJson: hiTranslation
    },
    ur: {
        name: "ur",
        text: "اردو",
        langJson: urTranslation
    }
};
